import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import { Link } from '@mui/material';

const drawerWidth = 240;
const data = [
  {
    Id: 1,
    image: "/Images/X.png",
    link: "https://x.com/bobwifhatsolana"
  },
  {
    Id: 2,
    image: "/Images/telegram.png",
    link: "https://t.me/BobWifHatSolana"
  },

]

const ListitemText = styled(ListItemText)`
    color : #B9B9B9; 
    font-size: 17px;   
    min-width: 100px;
    &:hover{  
      color : white;
    };
`

function ResponsiveDrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle}
      sx={{ textAlign: 'center' }}
    >
      <Divider />
      <List>
        {data.map((item, i) => (
          <ListItem key={i} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListitemText>
                {item.text}
              </ListitemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List> 
      <Divider />
     
    </Box>
  );

//   const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' ,paddingY:"15px"}}>
      <CssBaseline />
      <AppBar component="nav" sx={{ background: "transparent", position:'relative', boxShadow:'none' }}>
        <Toolbar margin="27px 0px">
          <Box
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'block', md: 'block' } }}
          > 
          <img src="/Images/logo.png" alt="logo" style={{width:'70px'}}></img>
          </Box>
          <Box sx={{ display: { xs: 'block', md: 'block' } }}>
            <Box>
              {data.map((item, i) => (
                <Button key={i} href={item.link} target='blank' sx={{ minWidth:"40px",height:"40px",margin:"5px",
                  color: '#fff',
                  backgroundColor:'#126CA2',
                  '&:hover': {
                    color: 'white',
                    backgroundColor:'#126CA2',
                  }
                }}>
                  <img src={item.image} alt="img" width="20px"/>
                </Button>
              ))}
              <Button sx={{ minWidth:"40px",height:"40px",margin:"5px",
                  color: '#fff',
                //   minWidth: '100px', 
                  backgroundColor:'#126CA2',
                  padding: "0px 10px",
                  
                  '&:hover': {
                    color: 'white',  backgroundColor:'#126CA2',
                  }
                }}>
                Buy Now
                </Button>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
 
    </Box>
  );
}

ResponsiveDrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawerAppBar;