import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import ResponsiveDrawerAppBar from '../../Component/Navbar'
 


const Main = () => { 


  return (  
    <Box sx={{minHeight:"100dvh"}}> 
    {/* <Box sx={{width: '100%',backgroundColor: '#126CA2'}}>  
       <Typography sx={{fontSize:'18px', color:'white', textAlign:'center' , padding:'10px 0px'}}> 
        CA: TBA
       </Typography>
     </Box> */}
     <Container sx={{position:"relative",minHeight:"calc(100dvh)"}}>  
        <ResponsiveDrawerAppBar />
        <img src='./Images/heroImage.png' alt='image' style={{position:"absolute",bottom:"0",left:"0",maxWidth:"55%",zIndex:"-1"}} />
        <Grid container display={"flex"} justifyContent={"end"} alignContent={"center"} minHeight={"calc(100dvh - 280px)"}>
            <Grid item xs={12} sm={8} md={6.1}>
                <img src='./Images/heading.png' alt='image' style={{maxWidth:"100%"}} />
            </Grid>
            <Grid item xs={12} sm={8} md={6}>
                <Typography variant='h6' color={"#000"} fontFamily={""} fontWeight={"bold"}> 
                    Name: Bobwifhat <br />
                    Ticker: $BIF <br />
                    Supply: 1Bil <br />
                    Tax: 0/0
                </Typography> 
            </Grid>
        </Grid>
     </Container> 
    </Box>
  )
}

export default Main